<template>
  <div class="wrapper__header">
    <header class="header">
      <div class="header__container">
        <a data-v-41d6f4fc="" class="navbar-brand">
          <svg
            @click="isActiveBarraLateralAdmin = !isActiveBarraLateralAdmin"
            data-v-41d6f4fc=""
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 100 100"
          >
            <defs data-v-41d6f4fc="">
              <clipPath data-v-41d6f4fc="" id="clip-side_bar_icon">
                <rect data-v-41d6f4fc="" width="100" height="100"></rect>
              </clipPath>
            </defs>
            <g
              data-v-41d6f4fc=""
              id="side_bar_icon"
              clip-path="url(#clip-side_bar_icon)"
            >
              <g
                data-v-41d6f4fc=""
                id="Group_2"
                data-name="Group 2"
                transform="translate(-2803 -84)"
              >
                <rect
                  data-v-41d6f4fc=""
                  id="Rectangle_2"
                  data-name="Rectangle 2"
                  width="98"
                  height="17.043"
                  rx="4"
                  transform="translate(2804 96)"
                ></rect>
                <rect
                  data-v-41d6f4fc=""
                  id="Rectangle_3"
                  data-name="Rectangle 3"
                  width="98"
                  height="17.043"
                  rx="4"
                  transform="translate(2804 125.826)"
                ></rect>
                <rect
                  data-v-41d6f4fc=""
                  id="Rectangle_4"
                  data-name="Rectangle 4"
                  width="98"
                  height="17.043"
                  rx="4"
                  transform="translate(2804 155.652)"
                ></rect>
              </g>
            </g>
          </svg>
        </a>
        <div data-v-41d6f4fc="" class="header__title" v-show="!isAuthenticated">
          <a
            data-v-41d6f4fc=""
            class="nav-link header_icon_admin"
            style="
              margin-left: 20px;
              margin-right: 20px;
              z-index: 2;
              cursor: pointer;
            "
          >
            <strong data-v-41d6f4fc="" @click="showModalLogin">
              Ingresar
            </strong>
          </a>
        </div>
        <div data-v-41d6f4fc="" class="header__title" v-show="isAuthenticated">
          <a
            data-v-41d6f4fc=""
            class="nav-link header_icon_admin"
            style="
              margin-left: 20px;
              margin-right: 20px;
              z-index: 2;
              cursor: pointer;
            "
          >
            <strong data-v-41d6f4fc="" @click="refresh">
              {{ palabras }}
            </strong>
          </a>
        </div>
        <div style="display: flex; margin-left: auto; margin-right: 20px">
          <div v-show="isAuthenticated && businessLocalId !== 0 && user.admin">
            <StatusBadge :status="colorLocalStatus">
              {{ storeStatus }}
            </StatusBadge>
          </div>
        </div>
      </div>
    </header>
    <div class="pending_payment_notification" v-if="pendingPayment">
      <strong style="margin-right: 10px">Pago pendiente:</strong>Tiene un recibo
      pendiente. {{ mensajeTolerancia }}
      <div style="margin-left: 10px">
        <!--    <strong>Ir a Pagos del Sistema.</strong>-->
      </div>
    </div>
    <transition name="slide-fade">
      <div
        v-show="isActiveBarraLateralAdmin"
        class="fondo__menu__lateral__modal"
        @click="isActiveBarraLateralAdmin = !isActiveBarraLateralAdmin"
      ></div>
    </transition>
    <transition name="slide-fade">
      <RebrandBarraLateral
        v-show="currentRouteName() !== undefined"
        :isActive="isActiveBarraLateralAdmin"
        :routeName="currentRouteName()"
        @close="isActiveBarraLateralAdmin = false"
      ></RebrandBarraLateral>
    </transition>
  </div>
</template>

<script>
import RebrandBarraLateral from "@/components/MainBarraLateral";
import moment from "moment";
import { mapMutations, mapState } from "vuex";
import LocalServices from "@/services/local-services";
import StatusBadge from "@/components/new/buttons/StatusBadge";
export default {
  name: "RebrandHeader",
  components: { StatusBadge, RebrandBarraLateral },
  data() {
    return {
      titulo: "INICIO",
      isActiveBarraLateralAdmin: false,
      fechaLimite: "",
      fechaActual: moment(),
    };
  },
  watch: {
    user: async function () {
      if (this.user.admin) {
        await this.loadLocalInfo();
      }
    },
  },
  computed: {
    ...mapState([
      "isAuthenticated",
      "user",
      "localSuperAdmin",
      "businessLocalId",
      "adminLocalInfo",
    ]),
    diasRestantes() {
      return this.fechaLimite.diff(this.fechaActual, "days");
    },
    mensajeTolerancia() {
      moment.locale("es");
      const diaSemana = this.fechaLimite.format("dddd");
      const fechaFormateada = this.fechaLimite.format(
        "D [de] MMMM [de] YYYY HH:mm"
      );
      if (this.fechaActual.isAfter(this.fechaLimite)) {
        return `Servicio suspendido hasta que regularicen su pago`;
      }
      return `Si no cancela hasta el ${diaSemana}, ${fechaFormateada} (en ${this.diasRestantes} días) se suspenderá su servicio.`;
    },
    palabras() {
      const route_name = {
        orders: "Órdenes",
        ordersSuperAdmin: `Órdenes - ${this.localSuperAdmin.name}`,
        inhouseOrdersSuperAdmin: `Pedidos de salón - ${this.localSuperAdmin.name}`,
        historicSalesSuperAdmin: `Historial diario - ${this.localSuperAdmin.name}`,
        cajaSuperAdmin: `Caja: ${this.localSuperAdmin.name}`,
        cajaAdmin: `Caja`,
        motorizedList: "Motorizados",
        historic_sales: "Historial de ventas",
        assignOrderDelivery: "Ingresar pedidos",
        customerPoints: "Asignar o canjear puntos",
        adminProfile: "Perfil",
        adminConfig: "Configuración del local",
        "configuracion.solo": "Configuración",
        home: "Inicio",
        soldProductsDashboard: "Platos vendidos - gráfico",
        salesDashboard: "Ventas - gráficos",
        motorizedDashboard: "Motorizados - gráficos",
        localPerformance: "Desempeño de locales",
        historicSales: "Histórico - ventas",
        sunatReport: "Reporte - Sunat",
        busquedaUsuario: "Búsqueda de usuario",
        insumosConsumidos: "Reporte - Insumos consumidos",
        availabilityRawData: "Disponibilidad de productos",
        historicPlatesSold: "Histórico - Platos vendidos",
        motorizedReport: "Histórico - motorizados",
        userDatabase: "Histórico - usuarios",
        salesPerUser: "Histórico - ventas por usuario",
        canceledOrders: "Pedidos anulados",
        couponReport: "Reporte de cupones simplificado",
        menu: "Menú/Carta",
        menuList: "Menú",
        menuListSuperAdmin: `Menú: ${this.localSuperAdmin.name}`,
        "perfil.cuenta": "Perfil",
        sendNotifcationScreen: "Enviar Push-up Notification",
        webConfig: "Configuración de la Web-Admin",
        configLocalSuperAdmin: `Configuración - ${this.localSuperAdmin.name}`,
        schedulesConfig: `Horarios - ${this.localSuperAdmin.name}`,
        couponsScreen: "Cupones y códigos promocionales",
        complaintsBook: "Libro de reclamaciones",
        jobSeeker: "Base de datos - Aspirantes",
        staffAccess: "Gestión de niveles de acceso",
        mailMarketing: "Campañas publicitarias",
        popUpBanner: "Banner publicitario en plataforma",
        deletedUsers: "Usuarios eliminados",
        PromotionalProductsCampaign: "Campañas de productos con descuento",
        ordersHeatMap: "Mapa de calor de pedidos delivery",
        businessDashboard: "Evolución del negocio",
        monthlyReports: "Reportes mensuales",
        ChatGPT: "Chatbot",
        inventory: "Almacén e insumos",
        proveedores: "Proveedoes",
        registroProveedores: "Registro",
        pucharseOrders: "Órdenes de compras",
        reservas: "Reservas",
        configReservas: "Configuración de reservas",
        productsDiscount: "Campañas de descuento",
        consumerSegmentation: "Segmentación de consumidores",
        virtualAssistant: "Asistente virtual",
        cashRegisterTurnsHistoric: "Reporte histórico - Cajas",
        tableTurnsHistoric: "Reporte histórico - Mesas",
        menuRecipe: `Recetas: ${this.localSuperAdmin.name}`,
        kitchenDisplaySystemSuperAdmin: `Cocina - ${this.localSuperAdmin.name}`,
        inhouseOrders: "Pedidos de salón",
        qr_code_generator: "Generador de códigos QR",
        qrCodeContactOnly: "QR para contacto",
        kitchenDisplaySystem: "Cocina",
        sunatQuickeatReport: "Sunat - Quick eat!",
        pantallaSalon: "Pantalla de salón de recojo",
        pantallaSalonSuperAdmin: `Pantalla de salón - ${this.localSuperAdmin.name}`,
        whatsAppChatBot: `ChatBot de WhatsApp`,
        webEcommerceParameters: 'Parámetros Web E-Commerce',
        everyuikit: `Every ui kit`,
      };
      return route_name[this.$route.name] ?? "Inicio";
    },

    storeStatus() {
      if (
        this.adminLocalInfo.is_available_local &&
        this.adminLocalInfo.is_in_area
      ) {
        return "Tienda en línea";
      } else if (!this.adminLocalInfo.is_available_local) {
        return "Tienda no disponible";
      } else if (!this.adminLocalInfo.is_in_area) {
        return "Fuera de horario de atención";
      } else {
        return "";
      }
    },

    colorLocalStatus() {
      if (
        this.adminLocalInfo.is_available_local &&
        this.adminLocalInfo.is_in_area
      ) {
        return null;
      } else {
        return "danger";
      }
    },

    pendingPayment() {
      return (
        this.isAuthenticated &&
        10 < new Date().getDate() &&
        this.user.is_pending_payment
      );
    },
  },

  async created() {
    this.fechaLimite = moment({
      year: this.fechaActual.year(),
      month: this.fechaActual.month(),
      date: 16,
      hour: 23,
      minute: 59,
      second: 0,
    });
    if (this.businessLocalId !== 0 && this.user.admin) {
      await this.loadLocalInfo();
    }
  },
  methods: {
    ...mapMutations(["setAdminLocalInfo", "setShowModalLoginRegister"]),

    showModalLogin() {
      this.setShowModalLoginRegister(true);
    },
    currentRouteName() {
      return this.$route.name;
    },
    async loadLocalInfo() {
      try {
        let response = await LocalServices.get_local_info(this.user.local);
        if (response.data.success) {
          this.setAdminLocalInfo(response.data.data);
        } else {
          this.$toast.error(response.data.message);
        }
      } catch (error) {
        this.$toast.error(error);
      }
    },
    refresh() {
      location.reload();
    },
  },
};
</script>

<style scoped>
.wrapper__header {
  /*    position: sticky;
    top: 0;*/
  z-index: 10;
}

.fondo__menu__lateral__modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 3;
  background: #00000060;
  left: 0;
  top: 0;
  transition: 0.3s ease all;
  cursor: pointer;
}

.header {
  position: sticky;
  top: 0;
  background-color: white;
  box-shadow: 0 1px 3px var(--plomito);
  z-index: 10;
}

.header__container {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 56px;
  padding: 0 16px;
  box-shadow: 0 1px 3px var(--plomito);
}

.navbar-brand {
  height: 25px;
  cursor: pointer;
}

.header__title a {
  transition: color 0.3s linear;
}

.header__title a:hover {
  color: #fca600;
}

.pending_payment_notification {
  display: flex;
  justify-content: center;
  color: white;
  background: #c22f29;
  width: 100%;
  padding: 10px 16px;
  font-size: 14px;
  z-index: 3;
}

.header_icon_admin {
  display: inline;
  transform: scale(1.1);
}

@media only screen and (min-width: 1024px) {
  .header__container {
    padding: 0 40px;
    gap: 20px;
  }
  .header__title {
    font-size: 24px;
  }
}
</style>
