import { createWebHistory, createRouter } from "vue-router";

import store from "@/store/index";
import User from "@/services/user-services";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/qr_code_generator",
    name: "qr_code_generator",
    component: () => import("@/views/digitalMarketing/QrCodeGenerator.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/qrCodeContactOnly",
    name: "qrCodeContactOnly",
    component: () => import("@/views/QrCodeContactOnly.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/webEcommerceParameters",
    name: "webEcommerceParameters",
    component: () => import("@/views/WebConstructorView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kitchenDisplaySystem",
    name: "kitchenDisplaySystem",
    component: () => import("@/views/KitchenDisplaySystem.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kitchenDisplaySuperAdmin/:idLocal",
    name: "kitchenDisplaySystemSuperAdmin",
    component: () => import("@/views/KitchenDisplaySystem.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cajas",
    name: "cajaAdmin",
    component: () => import("@/views/CajaAdmin.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cajaSuperAdmin/:idLocal",
    name: "cajaSuperAdmin",
    component: () => import("@/views/CajaAdmin.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pantallaSalon",
    name: "pantallaSalon",
    component: () => import("@/views/QueueScreen.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pantallaSalonSuperAdmin/:idLocal",
    name: "pantallaSalonSuperAdmin",
    component: () => import("@/views/QueueScreen.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reservas",
    name: "reservas",
    component: () => import("@/views/BookingManagement.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mapRestaurant",
    name: "mapRestaurant",
    component: () => import("@/views/MapRestaurant.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configReservas",
    name: "configReservas",
    component: () => import("@/views/BookingConfiguration.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("@/views/ActiveOrders.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ordersSuperAdmin/:idLocal",
    name: "ordersSuperAdmin",
    component: () => import("@/views/ActiveOrders.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/monthlyReports",
    name: "monthlyReports",
    component: () => import("@/views/MonthlyReports.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/historicSalesSuperAdmin/:idLocal",
    name: "historicSalesSuperAdmin",
    component: () => import("@/views/DailySales.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configLocalSuperAdmin/:idLocal",
    name: "configLocalSuperAdmin",
    component: () => import("@/views/LocalConfiguration.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/motorizedList",
    name: "motorizedList",
    component: () => import("@/views/MotorizedList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/historic_sales",
    name: "historic_sales",
    component: () => import("@/views/DailySales.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/menuList",
    name: "menuList",
    component: () => import("@/views/MenuList.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/menuListSuperAdmin/:idLocal",
    name: "menuListSuperAdmin",
    component: () => import("@/views/MenuList.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/recipe/:idLocal",
    name: "menuRecipe",
    component: () => import("@/views/MenuRecipe.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/inventory",
    name: "inventory",
    component: () => import("@/views/Inventory.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/proveedores",
    name: "proveedores",
    component: () => import("@/views/Proveedores.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/registroProveedores",
    name: "registroProveedores",
    component: () => import("@/views/RegistroProveedores.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/pucharseOrders",
    name: "pucharseOrders",
    component: () => import("@/views/PurcharseOrders.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/assignOrderDelivery",
    name: "assignOrderDelivery",
    component: () => import("@/views/AssignOrderDelivery.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/inhouseOrders",
    name: "inhouseOrders",
    component: () => import("@/views/InhouseOrders.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/inhouseOrdersSuperAdmin/:idLocal",
    name: "inhouseOrdersSuperAdmin",
    component: () => import("@/views/InhouseOrders.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/customerPoints",
    name: "customerPoints",
    component: () => import("@/views/digitalMarketing/CustomerPoints.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/couponsScreen",
    name: "couponsScreen",
    component: () => import("@/views/digitalMarketing/CouponsScreen.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/businessDashboard",
    name: "businessDashboard",
    component: () => import("@/views/BusinessDashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/salesDashboard",
    name: "salesDashboard",
    component: () => import("@/views/SalesDashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/soldProductsDashboard",
    name: "soldProductsDashboard",
    component: () => import("@/views/SoldProductsDashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/motorizedDashboard",
    name: "motorizedDashboard",
    component: () => import("@/views/motorizedDashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/ordersHeatMap",
    name: "ordersHeatMap",
    component: () => import("@/views/OrdersHeatMap.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/localPerformance",
    name: "localPerformance",
    component: () => import("@/views/statistics_reports/LocalPerformance.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sunatReport",
    name: "sunatReport",
    component: () => import("@/views/statistics_reports/SunatReport.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sunatQuickeatReport",
    name: "sunatQuickeatReport",
    component: () =>
      import("@/views/statistics_reports/SunatQuickEatReport.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cashRegisterTurnsHistoric",
    name: "cashRegisterTurnsHistoric",
    component: () =>
      import("@/views/statistics_reports/CashRegisterTurnsHistoricView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tableTurnsHistoric",
    name: "tableTurnsHistoric",
    component: () =>
        import("@/views/statistics_reports/TableTurnsHistoricView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/historicSales",
    name: "historicSales",
    component: () => import("@/views/statistics_reports/HistoricSalesReport.vue"),
    meta: {
      requiresAuth: true,
    },
    props: route => ({ orderStatus: route.query.orderStatus }) // Extrae el query 'orderStatus' y lo pasa como prop
  },
  {
    path: "/availabilityRawData",
    name: "availabilityRawData",
    component: () =>
      import("@/views/statistics_reports/ProductAvailabilityReport.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/historicPlatesSold",
    name: "historicPlatesSold",
    component: () =>
      import("@/views/statistics_reports/HistoricSoldPlatesView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/couponReport",
    name: "couponReport",
    component: () => import("@/views/statistics_reports/CouponReport.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/canceledOrders",
    name: "canceledOrders",
    component: () => import("@/views/old_statistics_reports/OldCanceledOrders.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/motorizedReport",
    name: "motorizedReport",
    component: () => import("@/views/statistics_reports/MotorizedReport.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/userDatabase",
    name: "userDatabase",
    component: () => import("@/views/statistics_reports/UserDatabaseReport.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/deletedUsers",
    name: "deletedUsers",
    component: () => import("@/views/old_statistics_reports/DeletedUsers.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/salesPerUser",
    name: "salesPerUser",
    component: () => import("@/views/statistics_reports/SalesPerUserReport.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/busquedaUsuario",
    name: "busquedaUsuario",
    component: () => import("@/views/statistics_reports/UserSearchReport.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/insumosConsumidos",
    name: "insumosConsumidos",
    component: () => import("@/views/statistics_reports/IngredientsConsumedReport.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sendNotifcationScreen",
    name: "sendNotifcationScreen",
    component: () => import("@/views/digitalMarketing/SendNotifcation.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/productsDiscount",
    name: "productsDiscount",
    component: () => import("@/views/digitalMarketing/PromotionalProductsCampaign.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/consumerSegmentation",
    name: "consumerSegmentation",
    component: () => import("@/views/CustomerSegmentation.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/virtualAssistant",
    name: "virtualAssistant",
    component: () => import("@/views/VirtualAssistant.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/popUpBanner",
    name: "popUpBanner",
    component: () => import("@/views/digitalMarketing/PopupBanner.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mailMarketing",
    name: "mailMarketing",
    component: () => import("@/views/digitalMarketing/AdvertisementCampaigns.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/complaintsBook",
    name: "complaintsBook",
    component: () => import("@/views/ComplaintsBook.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/whatsAppChatBot",
    name: "whatsAppChatBot",
    component: () => import("@/views/digitalMarketing/WhatsAppChatBot.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/chatbot",
    name: "chatGPT",
    component: () => import("@/views/ChatGPT.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobSeeker",
    name: "jobSeeker",
    component: () => import("@/views/JobSeeker.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/webConfig",
    name: "webConfig",
    component: () => import("@/views/WebConfiguration.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/staffAccess",
    name: "staffAccess",
    component: () => import("@/views/BusinessStaffAccess.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tableManagement",
    name: "tableManagement",
    component: () => import("@/views/TableManagement.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/adminProfile",
    name: "adminProfile",
    component: () => import("@/views/PerfilAdmin.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/adminConfig",
    name: "adminConfig",
    component: () => import("@/views/LocalConfiguration.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/everyuikit",
    name: "everyuikit",
    component: () => import("@/components/Recursos/EveryUIKitComponent.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.onError((error) => {
  const errorMessage = error.message.toLowerCase();
  const hasChunkError =
    errorMessage.includes("chunk failed") ||
    errorMessage.includes("loading chunk") ||
    errorMessage.includes("loading css chunk");

  if (hasChunkError) {
    const message =
      "Se ha lanzado una actualización de la web. Por favor, recarga la página para continuar.";
    setTimeout(() => {
      window.alert(message);
      window.location.reload();
    }, 1900);
  }
});

let firstOcurrency = true;
async function loadLocalStorageData() {
  let orderLocal = await JSON.parse(localStorage.getItem("orderLocal"));
  if (orderLocal) {
    store.commit("setOrderLocal", orderLocal);
  }
  let orderProducts = await JSON.parse(localStorage.getItem("orderProducts"));
  if (orderProducts) {
    orderProducts.forEach((product) => {
      store.commit("pushOrderProduct", product);
    });
  }
  let user = await JSON.parse(localStorage.getItem("user"));
  if (user) {
    await User.setAuthHeaders(user.token);
    store.commit("setUser", user);
    store.commit("setAuthenticatedTrue");
  } else {
    store.commit("setAuthenticatedFalse");
    //router.push("/")
  }
  let customerOrder = await JSON.parse(localStorage.getItem("customerOrder"));
  if (customerOrder) {
    store.commit("setCustomerOrder", customerOrder);
  }
  let historicTurnObj = await JSON.parse(
    localStorage.getItem("historicTurnObj")
  );
  if (historicTurnObj) {
    store.commit("setHistoricTurnObj", historicTurnObj);
  }
  let orderDetails = await JSON.parse(localStorage.getItem("orderDetails"));
  if (orderDetails) {
    store.commit("setOrderDetails", orderDetails);
  }
  let businessApi = await JSON.parse(localStorage.getItem("businessApi"));
  if (businessApi) {
    store.commit("setBusinessApi", businessApi);
  }
  let businessLocalId = await JSON.parse(
    localStorage.getItem("businessLocalId")
  );
  if (businessLocalId) {
    store.commit("setBusinessLocalId", businessLocalId);
  }
  let localSuperAdmin = await JSON.parse(
    localStorage.getItem("localSuperAdmin")
  );
  if (localSuperAdmin) {
    store.commit("setLocalSuperAdmin", localSuperAdmin);
  }
  let categorySelected = await JSON.parse(
    localStorage.getItem("categorySelected")
  );
  if (categorySelected) {
    store.commit("setCategorySelected", categorySelected);
  }
  let productSelected = await JSON.parse(
    localStorage.getItem("productSelected")
  );
  if (productSelected) {
    store.commit("setProductSelected", productSelected);
  }
  let insumoSelected = await JSON.parse(localStorage.getItem("insumoSelected"));
  if (insumoSelected) {
    store.commit("setInsumoSelected", insumoSelected);
  }
  let subRecipeSelected = await JSON.parse(
    localStorage.getItem("subRecipeSelected")
  );
  if (subRecipeSelected) {
    store.commit("setSubRecipeSelected", subRecipeSelected);
  }
  let businessProfileSelected = await JSON.parse(
    localStorage.getItem("businessProfileSelected")
  );
  if (businessProfileSelected) {
    store.commit("setBusinessProfileSelected", businessProfileSelected);
  }
  let adminLocalInfo = await JSON.parse(localStorage.getItem("adminLocalInfo"));
  if (adminLocalInfo) {
    store.commit("setAdminLocalInfo", adminLocalInfo);
  }
  let configurationsAdminWeb = await JSON.parse(
    localStorage.getItem("configurationsAdminWeb")
  );
  if (configurationsAdminWeb) {
    store.commit("setConfigurationsAdminWeb", configurationsAdminWeb);
  }
  let webVersion = await JSON.parse(localStorage.getItem("webVersion"));
  if (webVersion) {
    store.commit("setWebVersion", webVersion);
  }
  let documentTypeForPrint = await JSON.parse(
    localStorage.getItem("documentTypeForPrint")
  );
  if (documentTypeForPrint) {
    store.commit("setDocumentTypeForPrint", documentTypeForPrint);
  }
}


router.beforeEach(async (to, from, next) => {
  if (firstOcurrency) {
    await loadLocalStorageData();
    firstOcurrency = false;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.isAuthenticated) {
      next();
      return
    }
    await router.push("/");
  }
  next();
});
export default router;
