<template>
  <div class="modal__bg" @click="closeModalLogin()">
    <form
        class="modal__container-base opening-animation"
        @click.stop
        @submit.prevent="loginButton"
        ref="form"
    >
      <svg class="icon-close-modal"
           @click="closeModalLogin"
           width="24"
           height="24"
           viewBox="0 0 24 24"
           fill="none"
           xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
            fill="currentColor"
        />
      </svg>
      <div class="component_login-information">
        <h2 class="title-modal">¡Te damos la bienvenida!</h2>
        <p>Inice sesión para continuar</p>
      </div>
      <SelectComponent
          v-if="socios_page"
          :optionsSelect="business_list"
          :name="'select_business_name'"
          :valueObj="'name'"
          :required="true"
          :placeholder="'Nombre del negocio'"
          :bg_color="'var(--primary_white)'"
          v-model="businessName"
          :key="reset_key"
      >
      </SelectComponent>
      <InputComponent
          :placeholder="'Correo'"
          :type="'email'"
          :required="true"
          :bg_color="'#F7F7F7'"
          v-model="login.email"
      >
      </InputComponent>
      <PasswordComponent
          :placeholder="'Contraseña'"
          :required="true"
          :bg_color="'#F7F7F7'"
          :disabled="loading"
          v-model="login.password"
      >
      </PasswordComponent>
      <ButtonPrimary type="submit" :disabled="loading" :loading="loading">
        {{ login_words }}
      </ButtonPrimary>
    </form>
  </div>
</template>

<script>
import AdminServices from "@/services/admin-services";
import User from "@/services/user-services";
import { mapMutations, mapState } from "vuex";
import InputComponent from "@/components/new/inputs/InputComponent";
import PasswordComponent from "@/components/new/inputs/PasswordComponent";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary";
import SelectComponent from "@/components/new/inputs/SelectComponent";
export default {
  name: "ModalLogin",
  props: ["active"],
  components: {
    SelectComponent,
    ButtonPrimary,
    PasswordComponent,
    InputComponent,
  },
  data() {
    return {
      loading: false,
      businessName: null,
      login: {
        email: "",
        password: "",
        platform: "Web",
      },
      business_list: [],
      notificationStatus: {
        takeout: true,
        reservation: true,
        delivery: true,
        auto_print_orders: false,
        auto_print_invoices: false,
      },
      reset_key: 0,
      visible_password: false,
      socios_page: false,
    };
  },
  computed: {
    ...mapState(["user"]),

    login_words() {
      if (this.loading) {
        return "Cargando...";
      } else {
        return "Ingresar";
      }
    },
  },
  watch: {
    active() {
      this.reset_key += 1;
      this.loading = false;
      this.login.email = "";
      this.login.password = "";
    },
  },
  beforeCreate() {
    if (this.$refs.form) {
      this.$refs.form.classList.replace(
          "closing-animation",
          "opening-animation"
      );
    }
  },
  async mounted() {
    let url = window.location.href;
    let urlObj = new URL(url);
    let subdominio = urlObj.hostname.split(".")[0].toLowerCase();

    let allowed_list = ["localhost", "socios", "respaldo", "qe", "test"];

    if (allowed_list.includes(subdominio)) {
      this.socios_page = true;
      await this.getBusinessList();
    } else {
      this.businessName = {
        name_app: subdominio,
      };
    }
  },
  methods: {
    ...mapMutations([
      "setUser",
      "setAuthenticatedTrue",
      "setBusinessApi",
      "setBusinessLocalId",
      "setConfigurationsAdminWeb",
      "setShowModalLoginRegister",
      'setIsNotManualNavigation'
    ]),

    async getBusinessList() {
      let response = await AdminServices.getBusinessList();
      if (response.data.success) {
        this.business_list = response.data.data;
        this.business_list = this.business_list.filter((item) => {
          if (item.id !== 45) {
            return item;
          }
        });
        this.business_list.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
      } else {
        this.$toast.error(response.data.message);
      }
    },
    closeModalLogin() {
      this.setShowModalLoginRegister(false);
    },
    async loginButton() {
      if (this.businessName === null) {
        this.$toast.error(
            "Debe seleccionar el nombre del negocio donde labora."
        );
        return;
      }
      let urlString =
          "https://" + this.businessName.name_app.trim().toLowerCase();
      this.setBusinessApi(urlString);
      try {
        this.loading = true;
        await User.setBusinessHeader(this.businessName.name_app);
        let response = await User.login(this.login);

        if (response.data.success) {
          await User.setAuthHeaders(response.data.data.token);
          this.setBusinessLocalId(response.data.data.local);
          if (response.data.data.is_staff) {
            for (const key in this.notificationStatus) {
              if (Object.hasOwnProperty.call(this.notificationStatus, key)) {
                this.notificationStatus[key] = false;
              }
            }
          }
          this.setConfigurationsAdminWeb(this.notificationStatus);
          this.$toast.success(`Bienvenido ${response.data.data.full_name}`);
          this.setUser(response.data.data);
          this.setAuthenticatedTrue();
          this.loading = true;
          this.$emit("close");
          this.setIsNotManualNavigation()
          if (this.user.has_invoice_quickeat && this.user.admin) {
            this.$router.push("/cajas");
            return;
          }
          if (this.user.admin) {
            this.$router.push("/orders");
            return;
          }
          if (this.user.waiter) {
            this.$router.push("/inhouseOrders");
            return;
          }
          if (this.user.chef) {
            this.$router.push("/kitchenDisplaySystem");
            return;
          }
          return;
        }
        this.loading = false;
        this.$toast.error(response.data.message);
      } catch (error) {
        this.$toast.error(error);
        this.loading = false;
      }
    },
  },
};
</script>

<style>
h2 {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: var(--semibold);
}

p {
  font-weight: var(--regular);
}

.component_login-information {
  text-align: center;
}
</style>
